import React, { Component } from "react"
import { Link } from "gatsby"
import moment from 'moment';
import 'moment/locale/fr';
import Layout from "../components/layout"
import MetaTags from '../components/MetaTags.js';
import { graphql } from 'gatsby'
// const _ = require('lodash');
const axios = require('axios');

// const IndexPage = (props) => {
class EnTemplate extends Component {

    constructor() {
        super()
        this.state = {
            listBlog: [],
            subscribed_to_mailchimpe_loader: false,
            user_mail: "",
            showBlog: 6,
            functionevent: function (e) {
                this.codeKeyboard(e)
            }.bind(this),
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.state.functionevent, false);
        this.wikiMount = true
        window.addEventListener('scroll', function (event) {
            if (this.wikiMount && window.scrollY > document.body.clientHeight - document.documentElement.clientHeight - 1200) {
                this.setState({ showBlog: this.state.showBlog + 6 })
            }
        }.bind(this));
        setTimeout(function () {
            this.setState({ r: 1 })
        }.bind(this), 250)
    }
    componentWillUnmount() {
        this.wikiMount = false
        document.removeEventListener("keydown", this.state.functionevent, false);
    }

    codeKeyboard(e) {
        if (this.inputEmail === document.activeElement && e.code === 'Enter') this.addMailChimp()
    }

    validateEmail() {
        var email = this.state.user_mail
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //return re.test(String(email).toLowerCase());
        if (re.test(String(email).toLowerCase())) return false
        else return true
    }

    addMailChimp() {
        if (this.validateEmail()) {
            return
        }
        else {
            this.setState({ subscribed_to_mailchimpe_loader: true })
            var user_mail_sub = {
                'email_address': this.state.user_mail,
                'status': 'subscribed',
                'LANGUAGE': 'en',
                "from_blog": true
            }
            axios.post('https://cors-anywhere.herokuapp.com/https://app.api.sommelierduparfum.me/mail_user_subscribed', user_mail_sub, { headers: { 'Accept': 'application/json', 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', } }).then((res => {
                this.setState({ subscribed_to_mailchimpe: true, subscribed_to_mailchimpe_loader: false })
                setTimeout(
                    function () {
                        this.setState({ subscribed_to_mailchimpe: false, user_mail: "" })
                    }.bind(this), 1000
                )
            }))
                .catch((resp =>
                    alert('An error occured, sorry for that')))
        }
    }

    goodPost(postListTmp) {
        // console.log(postListTmp)
        var edges = []
        postListTmp.forEach(element => {
            if (element.node.lang === "en") edges.push(element)
        });
        return edges
    }

    render() {
        // var nbr_max_tags = 6
        moment.locale('en')
        // console.log(this.props)
        const postList = this.props.data.allBlogPostUnique;
        postList.edges = this.goodPost(postList.edges)
        // const tagList = this.props.data.allBlogPostUnique.group;
        var lien_image_share = "https://s3.eu-central-1.amazonaws.com/fragrancebutler.me/share_image/CTA_share/share_image_sdp.png"
        return (
            <Layout>
                <MetaTags
                    title={"Blog Sommelier du Parfum | All about perfume"}
                    description={"Want to know more about the world of perfume? Discover perfumery, its history and latest news as well as all the tips to get the most out of your perfumes."}
                    thumbnail={lien_image_share}
                    // url={url_post}
                    pathname={this.props.location.href}
                    keywords={[]}
                />
                <div className="sizeContainerListTot" style={{ paddingBottom: 60 }}>
                    <div className="" style={{ width: "100%", display: 'flex', flexWrap: "wrap" }}>
                        {postList.edges.map(({ node }, i) => (
                            i >= this.state.showBlog ? null :
                                <div key={'post-' + i} style={{}} className={(i) % 3 === 0 ? "div-blog-landing-big" : "div-blog-landing"}>
                                    <Link to={"/" + node.slug} style={{ textDecoration: "none" }} className="link" >
                                        <div className={(i) % 3 === 0 ? "div-blog-landing-image-big" : "div-blog-landing-image"}>
                                            <img src={node.image_header} className="banner-blog-landing" alt={'image_header_little_' + i} />
                                        </div>
                                        <div className={(i) % 3 === 0 ? "content-blog-landing-big" : "content-blog-landing"}>
                                            <div className="hover-black-border"></div>
                                            <div className={(i) % 3 === 0 ? "title-blog-landing-big" : "title-blog-landing"}>
                                                {node.title}
                                            </div>
                                            <div className={(i) % 3 === 0 ? "desc-blog-landing-big" : "desc-blog-landing"}>
                                                {node.description}
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                        ))}
                    </div>
                </div>
            </Layout>
        )
    }
}

export default EnTemplate

export const listQueryAndListQueryEn = graphql`
    query listQueryAndListQueryEn {
        allBlogPostUnique(sort: { order: DESC, fields: date }) {
        edges {
            node {
                date
                title
                description
                tags
                lang
                reading
                slug
                author
                image_header
            }
        }

      group(field: tags) {
        fieldValue
        totalCount
      }
    }
}
`
